import React from "react"
import { graphql, Link } from "gatsby"
import windowSize from "react-window-size"

import Stars from "../../components/blog/stars"
import Layout from "../../layout"
import SiteHead from "../../head"
import HeroContainer from "../../components/containers/hero"
import Title from "../../components/hero/title"
import LinkToParentPage from "../../components/link-to-parent-page"

import { guideContainer, guidePaddingBottom } from "../../utils/styles"

let currentLetter = ``

export function Head(props) {
  return (
    <SiteHead
      {...props}
      title={`All Tags`}
      description={`All tags categorizing posts on the Gatsby blog.`}
    />
  )
}

const TagsPage = ({ location, data: { allWpTag: tags } }) => {
  return (
    <Layout pathname={location.pathname}>
      <div
        css={theme => ({
          overflowX: `hidden`,
          position: `relative`,
          zIndex: 2,
          ...guidePaddingBottom(theme),
        })}
      >
        <Stars />
        <HeroContainer>
          <div css={theme => guideContainer(theme)}>
            <LinkToParentPage title="Return to Blog List" link="/blog" />
            <Title>All Tags</Title>
            <ul
              css={{
                display: `flex`,
                flexFlow: `row wrap`,
                justifyContent: `start`,
                padding: 0,
                margin: 0,
              }}
            >
              {tags?.nodes?.map(tag => {
                const firstLetter = tag.name.charAt(0).toLowerCase()
                const buildTag = (
                  <li
                    key={tag.name}
                    css={theme => ({
                      listStyleType: `none`,
                      paddingTop: theme.space[3],
                      paddingBottom: theme.space[3],
                      paddingLeft: theme.space[2],
                      paddingRight: theme.space[2],
                      margin: theme.space[3],
                    })}
                  >
                    <Link to={`/blog/tags/${tag.slug}/`}>
                      {tag.name} ({tag.posts.nodes.length})
                    </Link>
                  </li>
                )

                if (currentLetter !== firstLetter) {
                  currentLetter = firstLetter

                  return (
                    <React.Fragment key={`letterheader-${currentLetter}`}>
                      <h2
                        css={theme => ({
                          width: `100%`,
                          flexBasis: `100%`,
                          marginTop: theme.space[6],
                          marginBottom: theme.space[4],
                        })}
                      >
                        {currentLetter.toUpperCase()}
                      </h2>
                      {buildTag}
                    </React.Fragment>
                  )
                }

                return buildTag
              })}
            </ul>
          </div>
        </HeroContainer>
      </div>
    </Layout>
  )
}

export default windowSize(TagsPage)

export const pageQuery = graphql`
  query TagsPage {
    allWpTag(filter: { count: { gt: 0 } }) {
      nodes {
        count
        id
        name
        slug
        posts {
          nodes {
            id
          }
        }
      }
    }
  }
`
